import { GoogleAuthProvider } from "firebase/auth";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const YOUTUBE_CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;

export const FIREBASE_AUTH_CREDENTIAL = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG
);

export const FIREBASE_UI_CONFIG = {
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [
    { provider: GoogleAuthProvider.PROVIDER_ID, fullLabel: "Sign In" },
  ],
};
