import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AlertDialog } from "./Alert";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "720px",
    margin: "0 auto",
    "& .MuiTextField-root": {
      marginBottom: "16px",
    },
  },
  button: {
    alignSelf: "center",
    marginTop: "16px !important",
    backgroundColor: "#000 !important",
    paddingLeft: "20px !important",
    paddingRight: "20px !important",
  },
}));

const HRACalculator = () => {
  const classes = useStyles();
  const [salary, setSalary] = useState(0);
  const [rentPaid, setRentPaid] = useState(0);
  const [hraRec, setHraRec] = useState(0);
  const [isMetro, setIsMetro] = useState(true);
  const [hraExemption, setHraExemption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [alertDialogInfo, setAlertDialogInfo] = useState("");
  const [alertType, setAlertType] = useState("success");

  const calculateHRAExemption = () => {
    // Perform HRA exemption calculation based on the rules in India
    if (salary === 0 || hraRec === 0 || rentPaid === 0) {
      setAlertType("error");
      setAlertDialogInfo("Please input correct values!");
      setIsOpen(true);
      return;
    }

    let hraExemption = hraRec;
    let rentLimit = null;
    let calculatedRentPaid = rentPaid - 0.1 * salary;

    if (isMetro) {
      rentLimit = 0.5 * salary;
    } else {
      rentLimit = 0.4 * salary;
    }

    hraExemption = Math.min(rentLimit, calculatedRentPaid, hraRec);
    hraExemption = hraExemption < 0 ? 0 : hraExemption;
    setHraExemption(hraExemption);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMetro(JSON.parse((event.target as HTMLInputElement).value));
  };

  return (
    <div
      className={`contactus tw-p-4 tw-mt-8 md:tw-mt-0 md:tw-px-20 ${
        hraExemption ? "md:tw-py-12" : "md:tw-py-20"
      }`}
    >
      <div className={`${classes.form} form-inputs`}>
        <FormControl className="!tw-mb-4">
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            className="livin-in"
          >
            Living In:{" "}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={true}
              control={<Radio checked={isMetro} />}
              label="Metro City"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Other City"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          required
          type="tel"
          label="Basic Salary + D.A + Commision Received"
          value={salary ? salary : ""}
          onChange={(e) => setSalary(parseInt(e.target.value))}
        />
        <div className="tw-flex tw-space-x-2">
          <TextField
            required
            type="tel"
            label="HRA Received"
            value={hraRec ? hraRec : ""}
            onChange={(e) => setHraRec(parseInt(e.target.value))}
          />
          <TextField
            required
            type="tels"
            label="Actual Rent Paid"
            value={rentPaid ? rentPaid : ""}
            onChange={(e) => setRentPaid(parseInt(e.target.value))}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className={`${classes.button}`}
          onClick={calculateHRAExemption}
        >
          Calculate
        </Button>
        {hraExemption !== null ? (
          <p className="tw-text-center tw-font-bold tw-mt-8">
            Your House rent allowance is {hraExemption}
          </p>
        ) : (
          <></>
        )}
      </div>
      <AlertDialog
        type={alertType}
        info={alertDialogInfo}
        open={isOpen}
        setOpen={setIsOpen}
      />
    </div>
  );
};

export default HRACalculator;
