import { Skeleton } from "@mui/material";
import {
  CardSkeletonStyleH,
  CardSkeletonStyleV,
  ContentSkeletonHStyle,
  ContentSkeletonVStyle,
  MediaSkeletonSx,
  TagsSkeletonContainerSx,
  TagsSkeletonSx,
} from "../styles";

interface ArticleSkeletonProps {
  isVertical?: boolean;
  style?: React.CSSProperties;
}

const ArticleSkeleton = (props: ArticleSkeletonProps) => {
  const { isVertical = true, style = {} } = props;

  return (
    <div
      style={{
        ...(isVertical ? CardSkeletonStyleV : CardSkeletonStyleH),
        ...style,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{ ...MediaSkeletonSx, ...(isVertical ? { width: "100%" } : {}) }}
      />
      <div
        className="content"
        style={isVertical ? ContentSkeletonVStyle : ContentSkeletonHStyle}
      >
        <div
          className="tags"
          style={{
            ...TagsSkeletonContainerSx,
            ...(!isVertical ? { width: "10%" } : { width: "55%" }),
          }}
        >
          <Skeleton sx={TagsSkeletonSx} />
          <Skeleton sx={TagsSkeletonSx} />
        </div>
        <Skeleton
          sx={{ fontSize: "2rem", width: isVertical ? "75%" : "50%" }}
        />
        <Skeleton
          sx={{ fontSize: "4rem", width: isVertical ? "90%" : "70%" }}
        />
        <Skeleton
          sx={{ fontSize: "2rem", width: isVertical ? "65%" : "25%" }}
        />
      </div>
    </div>
  );
};

export default ArticleSkeleton;
