
import {
    Typography,
    Link, 
    Box, 
    IconButton
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none !important',
    },
    arrowIcon: {
      fontSize: '1rem',
      marginLeft: '0px'
    },
    button: {
        paddingLeft: '0px'
    }
}));


const LinkWithArrow = ({ to, text, classnames = "" }) => {
    
    const classes = useStyles();
    return (
      <Link component={RouterLink} to={to} className={`${classes.link} ${classnames}`}>
        <Typography component="span" variant="body1">
          <p className='tw-text-xl tw-text-black'>{text}</p>
        </Typography>
        <Box component="span" ml={1}>
          <IconButton size="small" className={classes.button}>
            <ArrowForwardIcon className={classes.arrowIcon} />
          </IconButton>
        </Box>
      </Link>
    );
};

export default LinkWithArrow;