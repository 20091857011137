import { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { IconButton, Stack, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import YoutubeVideo from "./YoutubeVideo";
import { YoutubeSectionSx, YoutubeVideoSx } from "../styles";
import { MAX_YOUTUBE_VIDEOS } from "../../contants";
import { fireYouTubeVideosEvent, useIsMobile } from "../../utils";

interface Props {
  ids: string[];
}

const YoutubeSection = (props: Props) => {
  const { ids } = props;
  const isMob = useIsMobile();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (change: number) => {
    const stepAfterChange = activeStep + change;
    if (stepAfterChange >= 0 && stepAfterChange < MAX_YOUTUBE_VIDEOS) {
      const iframes = document.querySelectorAll("iframe");
      fireYouTubeVideosEvent(iframes, "pauseVideo");
      setActiveStep(stepAfterChange);
    }
  };

  const handleVideoAutoPlay = () => {
    const videoSection = document.querySelector("#youtube-video");
    const iframe = document.querySelectorAll("iframe")?.[activeStep];
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fireYouTubeVideosEvent([iframe], "playVideo");
          } else {
            fireYouTubeVideosEvent([iframe], "pauseVideo");
          }
        });
      },
      { threshold: 0.5 }
    );
    observer.observe(videoSection);
  };

  // Experimental code
  // useEffect(() => {
  //   document.addEventListener("scroll", handleVideoAutoPlay);
  // }, []);

  return (
    <Stack id="youtube-video" sx={YoutubeSectionSx}>
      <Typography variant="h5" gutterBottom>
        Hey, We've got something to learn for you!
      </Typography>
      <Stack sx={YoutubeVideoSx}>
        {!isMob && (
          <IconButton
            sx={{
              marginRight: "40px",
            }}
            onClick={() => handleStepChange(-1)}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
        )}
        <SwipeableViews
          style={{ width: "650px" }}
          axis={"x"}
          index={activeStep}
          enableMouseEvents
        >
          {ids?.map((id) => (
            <YoutubeVideo
              key={id}
              embedId={id}
              iFrameSx={
                isMob
                  ? { width: "100%", height: "180px" }
                  : { width: "100%", height: "350px" }
              }
            />
          ))}
        </SwipeableViews>
        {!isMob && (
          <IconButton
            sx={{
              marginLeft: "40px",
            }}
            onClick={() => handleStepChange(1)}
            disabled={activeStep === MAX_YOUTUBE_VIDEOS - 1}
          >
            <KeyboardArrowRight fontSize="large" />
          </IconButton>
        )}
      </Stack>
      {isMob && (
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "12px",
          }}
        >
          <IconButton
            onClick={() => handleStepChange(-1)}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
          <IconButton
            onClick={() => handleStepChange(1)}
            disabled={activeStep === MAX_YOUTUBE_VIDEOS - 1}
          >
            <KeyboardArrowRight fontSize="large" />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default YoutubeSection;
