import React from "react";
import { Snackbar, Alert, AlertTitle } from "@mui/material";

export const AlertDialog = ({ type, info, open, setOpen }) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      className="tw-mt-20"
    >
      <Alert severity={type}>
        <AlertTitle>{type === "success" ? "Success" : "Error"}</AlertTitle>
        {info}
      </Alert>
    </Snackbar>
  );
};
