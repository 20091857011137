import React from "react";
import { Quill } from "react-quill";

const Undo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

const Redo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function handleUndoChange() {
  this.quill.history.undo();
}
function handleRedoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("attributors/style/size");
Size.whitelist = ["14px", "18px", "22px"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("attributors/style/font");
Font.whitelist = [
  "arial",
  "Comic sans MS",
  "Courier new",
  "georgia",
  "Times New Roman",
];
Quill.register(Font, true);
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#editor-toolbar",
    handlers: {
      undo: handleUndoChange,
      redo: handleRedoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
  "code-block",
];

export const EditorToolbar = () => (
  <div id="editor-toolbar">
    <span className="ql-formats">
      <button className="ql-undo">
        <Undo />
      </button>
      <button className="ql-redo">
        <Redo />
      </button>
      <button className="ql-clean" />
    </span>
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="Comic sans MS">Comic Sans MS</option>
        <option value="Courier new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="Times New Roman">Times New Roman</option>
      </select>
      <select className="ql-size" defaultValue="14px">
        <option value="14px">Small</option>
        <option value="18px">Medium</option>
        <option value="22px">Large</option>
      </select>
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
    </span>
  </div>
);

export default EditorToolbar;
