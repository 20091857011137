import HRACalculator from "./molecules/HRACalculator";

const Calculator = () => {
  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-4 tw-pt-24 md:tw-pb-20">
      <div className="calculator-heading tw-text-center">
        <h2 className="tw-text-4xl tw-font-bold tw-mb-4">HRA Calculator</h2>
        <p className="tw-text-md tw-font-medium">
          Calculate House Rent Allowance Online.
        </p>
        <p className="tw-font-light tw-text-center tw-mt-2 tw-mx-6">
          Please Note: You can now claim upto Rs. 60,000 deducions under Section
          80GG even if you do not recieve HRA.
        </p>
      </div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-mt-8 tw-w-4/5">
        <div className="calculator-info tw-flex tw-flex-col md:tw-w-1/3 tw-p-8 tw-pt-12 tw-text-white">
          <h2 className="tw-text-4xl tw-font-bold tw-mb-4">HRA Calculator</h2>
          <p className="tw-text-md tw-mb-4">
            For the purpose of above calculations, salary would be taken as
            Basic Pay + D.A. (if considered for retirement benefits){" "}
          </p>
          <p className="tw-text-md tw-font-light tw-mb-4">
            • Actual HRA received
          </p>
          <p className="tw-text-md tw-font-light tw-mb-4">
            • Rent paid less 10% of Salary
          </p>
          <p className="tw-text-md tw-font-light tw-mb-4">
            • 40% of Salary (50% of Salary in Mumbai, Delhi, Chennai and
            Kolkata)
          </p>
        </div>
        <HRACalculator />
      </div>
    </div>
  );
};

export default Calculator;
