const PrimaryColor = "#002a3a";

const AUTHOR = "author";
const ADMIN = "admin";
const USER = "user";
const ALL = "all";
const APPROVED = "approved";
const PENDING = "pending";
const ADMIN_PANEL = "admin_panel";
const LOGOUT = "logout";
const PROFILE = "profile";
const NOT_FOUND_ISSUE =
  "Sorry, you may have entered the wrong path. This route doesn't exist";
const ACCESS_ISSUE =
  "Sorry, you are not having access to this module, Please contact admin.";
const LOGIN_REQUIRED_ISSUE = "You need to be logged in to update the profile.";
const MAX_YOUTUBE_VIDEOS = 3;

const SocialLinks = [
  {
    id: "facebook",
    to: "https://www.facebook.com/NoTax.india",
  },
  {
    id: "instagram",
    to: "https://www.instagram.com/notax_india/",
  },
  {
    id: "twitter",
    to: "https://twitter.com/NoTax_India",
  },
  {
    id: "linkedin",
    to: "https://www.linkedin.com/company/notax/",
  },
  {
    id: "telegram",
    to: "https://t.me/notax_india",
  },
  {
    id: "youtube",
    to: "https://www.youtube.com/@notax_india",
  },
];

const TopBarMenuItems = [
  {
    id: "home",
    title: "Home",
    to: "/",
  },
  {
    id: "articles",
    title: "Articles",
    to: "/articles",
  },
  {
    id: "calculator",
    title: "HRA Calculator",
    to: "/calculator",
  },
  {
    id: "contact",
    title: "Contact Us",
    to: "/contact",
  },
];

const ProfileMenuItems = [
  {
    id: "profile",
    title: "Profile",
    to: "/profile",
  },
  {
    id: "admin_panel",
    title: "Admin Panel",
    to: "/admin",
  },
  {
    id: "logout",
    title: "Log out",
  },
];

const ArticlesStatus = [
  {
    id: "all",
    label: "All",
    value: "all",
  },
  {
    id: "approved",
    label: "Published",
    value: "approved",
  },
  {
    id: "pending",
    label: "Pending",
    value: "pending",
  },
];

export {
  PrimaryColor,
  SocialLinks,
  AUTHOR,
  ADMIN,
  USER,
  ProfileMenuItems,
  TopBarMenuItems,
  ArticlesStatus,
  ALL,
  APPROVED,
  PENDING,
  ADMIN_PANEL,
  LOGOUT,
  PROFILE,
  NOT_FOUND_ISSUE,
  ACCESS_ISSUE,
  LOGIN_REQUIRED_ISSUE,
  MAX_YOUTUBE_VIDEOS
};
