import {
  Box,
  Container,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  deleteAuthor,
  getAuthors,
  getQueries,
  makeAuthor,
} from "../services/api";
import { Add, Check, Close, Launch, RemoveCircle } from "@mui/icons-material";
import { useIsMobile } from "../utils";
import { ACCESS_ISSUE, ADMIN } from "../contants";
import NotFound from "./molecules/NotFound";
import { AlertDialog } from "./molecules/Alert";

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const AdminPanel = () => {
  const isMob = useIsMobile();

  const [activeTab, setActiveTab] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [modalData, setModalData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [alertDialogInfo, setAlertDialogInfo] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleDelete = (email) => {
    const isConfirmed = window.confirm(
      "Are you sure, you want to remove this author?"
    );
    if (isConfirmed) {
      setIsSaving(true);
      deleteAuthor({
        email: [email],
      })
        .then(() => {
          setIsOpen(true);
          setAlertType("success");
          setAlertDialogInfo("Author removed successfully!");
          setIsSaving(false);
          getAuthors().then(({ data }) => {
            setTableData(data);
          });
        })
        .catch(() => {
          setIsOpen(true);
          setAlertType("error");
          setAlertDialogInfo("Something went wrong, Please try again!");
          setIsSaving(false);
        });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (event) => {
    const {
      target: { value },
    } = event;
    setInputEmail(value);
  };

  const handleNewAuthor = () => {
    if (inputEmail) {
      setIsSaving(true);
      makeAuthor({
        email: [inputEmail],
      })
        .then(() => {
          setIsOpen(true);
          setAlertType("success");
          setAlertDialogInfo("Author added successfully!");
          setIsSaving(false);
          setIsInputOpen(false);
          getAuthors().then(({ data }) => {
            setTableData(data);
          });
        })
        .catch(() => {
          setIsOpen(true);
          setAlertType("error");
          setAlertDialogInfo("Something went wrong, Please try again!");
          setIsSaving(false);
        });
    }
  };

  useEffect(() => {
    if (activeTab === 0) {
      getAuthors().then(({ data }) => {
        setTableData(data);
      });
    } else {
      getQueries().then(({ data }) => {
        setTableData(data);
      });
    }
  }, [activeTab]);

  return localStorage.accesstype !== ADMIN ? (
    <NotFound msg={ACCESS_ISSUE} />
  ) : (
    <Container className="tw-pt-28 md:tw-pt-36 ">
      <Stack
        sx={{
          width: "100%",
        }}
      >
        <Typography variant="h4" className="tw-border-b-2 !tw-mb-8">
          Admin Panel
        </Typography>
      </Stack>
      <Stack
        sx={
          isMob
            ? {}
            : {
                flexDirection: "row",
              }
        }
      >
        <Stack
          sx={
            isMob
              ? {}
              : {
                  width: "15%",
                }
          }
        >
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100%",
              width: "100%",
            }}
          >
            <Tabs
              orientation={isMob ? "horizontal" : "vertical"}
              variant="scrollable"
              value={activeTab}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={
                isMob
                  ? {}
                  : { borderRight: 1, borderColor: "divider", width: "95%" }
              }
            >
              <Tab label="Authors" {...a11yProps(0)} />
              <Tab label="Queries" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </Stack>
        <Stack sx={isMob ? {} : { width: "85%" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">E-mail</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">
                      {activeTab === 0 ? (
                        <IconButton
                          aria-label="delete"
                          disabled={isSaving}
                          onClick={() => handleDelete(row.email)}
                        >
                          <RemoveCircle />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="View Query"
                          disabled={isSaving}
                          onClick={() => {
                            setModalData(row);
                            setIsModalOpen(true);
                          }}
                        >
                          <Launch />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isModalOpen && (
            <Modal
              open={isModalOpen}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  padding: "24px",
                  borderRadius: "12px",
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Query Info
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <span className="tw-font-bold">Name: </span>
                  {modalData?.name ?? ""}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <span className="tw-font-bold">Email: </span>
                  {modalData?.email ?? ""}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <span className="tw-font-bold">Mobile: </span>
                  {modalData?.phone ?? ""}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <span className="tw-font-bold">Message: </span>
                  {modalData.message ?? ""}
                </Typography>
              </Box>
            </Modal>
          )}
        </Stack>
      </Stack>
      {activeTab === 0 && (
        <Stack
          sx={{
            alignItems: "end",
            marginTop: "16px",
          }}
        >
          {isInputOpen ? (
            <Stack
              sx={{
                flexDirection: "row",
                gap: "12px",
              }}
            >
              <TextField
                required
                size="small"
                type="email"
                value={inputEmail}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleInputChange}
              />
              <IconButton
                sx={{
                  border: "1px solid gray",
                  borderRadius: "4px",
                }}
                aria-label="Add Author"
                disabled={isSaving}
                onClick={handleNewAuthor}
              >
                <Check />
              </IconButton>
              <IconButton
                sx={{
                  border: "1px solid gray",
                  borderRadius: "4px",
                }}
                aria-label="Add Author"
                disabled={isSaving}
                onClick={() => {
                  setIsInputOpen(false);
                  setInputEmail("");
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          ) : (
            <IconButton
              sx={{
                border: "1px solid gray",
                borderRadius: "4px",
              }}
              aria-label="Add Author"
              disabled={isSaving}
              onClick={() => setIsInputOpen(true)}
            >
              <Add />
            </IconButton>
          )}
        </Stack>
      )}
      <AlertDialog
        type={alertType}
        info={alertDialogInfo}
        open={isOpen}
        setOpen={setIsOpen}
      />
    </Container>
  );
};

export default AdminPanel;
