import { Stack } from "@mui/material";
import noDataSvg from "../../assets/icons/nodata.svg";
import { NoDataSx } from "../styles";

interface NoDataProps {
  term?: string;
}

const NoData = (props: NoDataProps) => {
  const { term = "" } = props;
  return (
    <Stack sx={NoDataSx}>
      <img
        src={noDataSvg}
        alt="No Data"
        style={{ height: "200px", marginBottom: "12px" }}
      />
      <h2 className="tw-text-2xl tw-mt-4">
        {term === ""
          ? "Sorry, No articles available"
          : `Sorry, no articles found matching "${term}"`}
      </h2>
    </Stack>
  );
};

export default NoData;
