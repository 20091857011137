import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

interface EditorProps {
  value: string;
  onChange: (content: string) => void;
}

export const Editor = (props: EditorProps) => {
  const { value, onChange } = props;
  return (
    <div className="text-editor tw-mt-2">
      <EditorToolbar />
      <ReactQuill
        style={{ height: "400px" }}
        theme="snow"
        value={value}
        modules={modules}
        formats={formats}
        onChange={onChange}
        placeholder="Write something awesome..."
      />
    </div>
  );
};

export default Editor;
