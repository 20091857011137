import React, { useEffect, useState } from "react";
import {
  Typography,
  Link,
  IconButton,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Avatar,
  ClickAwayListener,
} from "@mui/material";
import { Person, AdminPanelSettings } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import HomeIcon from "@mui/icons-material/Home";
import ArticleIcon from "@mui/icons-material/Article";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { StyledFirebaseAuth } from "react-firebaseui";
import { useAuthState } from "react-firebase-hooks/auth";

import logoSvg from "../../assets/icons/logo.svg";

// Services and config
import { FIREBASE_UI_CONFIG } from "../../config/config";
import auth from "../../services/auth";
import { login } from "../../services/api";
import { useIsMobile } from "../../utils";
import UiModeSwitch from "./UiModeSwitch";
import {
  ADMIN,
  ADMIN_PANEL,
  LOGOUT,
  PROFILE,
  ProfileMenuItems,
  TopBarMenuItems,
  USER,
} from "../../contants";

const useStyles = makeStyles((theme) => ({
  logo: {
    marginRight: "16px",
    height: "32px",
  },
  title: {
    flexGrow: 1,
  },
}));

const returnIcon = (id: string) => {
  if (id === "home") return <HomeIcon key={`icon_${id}`} className="tw-mr-4" />;
  else if (id === "articles")
    return <ArticleIcon key={`icon_${id}`} className="tw-mr-4" />;
  else if (id === "contact")
    return <AddIcCallIcon key={`icon_${id}`} className="tw-mr-4" />;
  else if (id === "logout")
    return <LogoutIcon key={`icon_${id}`} className="tw-mr-4" />;
  else if (id === "calculator")
    return <CalculateIcon key={`icon_${id}`} className="tw-mr-4" />;
  else if (id === "profile")
    return <Person key={`icon_${id}`} className="tw-mr-4" />;
  else if (id === "admin_panel")
    return <AdminPanelSettings key={`icon_${id}`} className="tw-mr-4" />;
};

const TopNav = () => {
  const [user]: any = useAuthState(auth);
  const navigate = useNavigate();

  const isMob = useIsMobile();
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem("isDarkMode")) ?? false
  );

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleUiModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    setIsDarkMode(checked);
    localStorage.setItem("isDarkMode", JSON.stringify(checked));
  };

  const handleLogout = () => {
    auth.signOut();
    localStorage.removeItem("access_token");
    localStorage.removeItem("accesstype");
    handleMenuClose();
    navigate("/");
  };

  useEffect(() => {
    if (user && user.accessToken) {
      localStorage.setItem("access_token", user.accessToken);
      login()
        .then(({ data }) => {
          localStorage.accesstype = data?.accesstype ?? USER;
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    }
  }, [user]);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("darkMode");
    } else {
      document.body.classList.remove("darkMode");
    }
  }, [isDarkMode]);

  return !isMob ? (
    <div className="topbar tw-z-10 tw-w-full tw-fixed tw-p-8px tw-flex tw-h-16 tw-items-center tw-shadow-2xl">
      <img
        src={logoSvg}
        alt="Logo"
        className="!tw-ml-6 !tw-w-28 tw-h-40px tw-cursor-pointer "
        onClick={() => navigate("/")}
      />
      <div className="tw-flex tw-grow tw-space-x-4 tw-justify-center">
        {TopBarMenuItems.map((item) => {
          return (
            <Link
              key={item.id}
              component={RouterLink}
              to={item.to}
              className="!tw-no-underline routes"
            >
              <Typography component="span" variant="body1">
                <p className={`tw-text-xl`}>{item.title}</p>
              </Typography>
            </Link>
          );
        })}
      </div>
      <UiModeSwitch
        sx={{ m: 1 }}
        checked={isDarkMode}
        onChange={handleUiModeChange}
      />
      {!user ? (
        <StyledFirebaseAuth uiConfig={FIREBASE_UI_CONFIG} firebaseAuth={auth} />
      ) : (
        <div className="!tw-mr-6 tw-flex tw-items-center">
          <p className="tw-font-bold tw-mr-2">
            {user.displayName ? user.displayName : user.email}
          </p>
          <IconButton
            size="large"
            edge="end"
            aria-label="Profile"
            aria-haspopup="true"
            onClick={(e) => {
              e.stopPropagation();
              setMenuAnchor(e.currentTarget);
            }}
            color="inherit"
          >
            <Avatar>{user.displayName?.substring(0, 1) ?? ""}</Avatar>
          </IconButton>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <Menu
              id="profile-menu"
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              keepMounted
              onClose={handleMenuClose}
            >
              {ProfileMenuItems.map((item) => {
                return item &&
                  (localStorage.accesstype !== ADMIN
                    ? item.id !== ADMIN_PANEL
                    : true) ? (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      if (item.id !== "logout") {
                        navigate(item.to);
                        handleMenuClose();
                      } else {
                        handleLogout();
                      }
                    }}
                  >
                    {returnIcon(item.id)} {item.title}
                  </MenuItem>
                ) : (
                  <></>
                );
              })}
            </Menu>
          </ClickAwayListener>
        </div>
      )}
    </div>
  ) : (
    <AppBar
      position="fixed"
      className="!tw-bg-white tw-h-16 tw-justify-center topbar"
    >
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <img
            src={logoSvg} // Replace with your logo image source
            alt="NoTax"
            className={classes.logo}
            onClick={() => navigate("/")}
          />
        </Typography>
        {!user && (
          <StyledFirebaseAuth
            uiConfig={FIREBASE_UI_CONFIG}
            firebaseAuth={auth}
          />
        )}
        <UiModeSwitch
          sx={{ m: 1 }}
          checked={isDarkMode}
          onChange={handleUiModeChange}
        />
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
        >
          <MenuIcon className="menu-icon" />
        </IconButton>

        <Menu
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          {[...TopBarMenuItems, ...ProfileMenuItems].map((item) => {
            return item &&
              (localStorage.accesstype !== ADMIN
                ? item.id !== ADMIN_PANEL
                : true) &&
              (!user ? ![LOGOUT, PROFILE].includes(item.id) : true) ? (
              <MenuItem
                key={item.id}
                onClick={() => {
                  if (item.id !== "logout") {
                    navigate(item.to);
                    handleMenuClose();
                  } else {
                    handleLogout();
                  }
                }}
              >
                {returnIcon(item.id)} {item.title}
              </MenuItem>
            ) : (
              <></>
            );
          })}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
