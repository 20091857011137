import axios from "axios";
import { BASE_URL, GOOGLE_API_KEY, YOUTUBE_CHANNEL_ID } from "../config/config";
import { MAX_YOUTUBE_VIDEOS } from "../contants";

axios.defaults.baseURL = BASE_URL;

export const login = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("login", {
        params: {
          idToken: localStorage.access_token,
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("GET /login failed with error: ", err);
        reject(err);
      });
  });
};

export const getArticles = ({
  limit,
  offset = 0,
  title = "",
  topics = "",
  status = "",
}) => {
  const params = {
    limit,
    offset,
  };
  if (title) params.title = title;
  if (topics) params.topics = topics;
  if (status) params.status = status;
  if (localStorage.access_token) params.idToken = localStorage.access_token;
  return new Promise((resolve, reject) => {
    axios
      .get("articles", {
        params,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("GET /articles failed with error: ", err);
        reject(err);
      });
  });
};

export const getArticle = ({ id }) => {
  const params = { id };
  if (localStorage.access_token) params.idToken = localStorage.access_token;
  return new Promise((resolve, reject) => {
    axios
      .get("articles", {
        params,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("GET /articles failed with error: ", err);
        reject(err);
      });
  });
};

export const newArticle = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "articles",
        {
          ...data,
        },
        {
          params: {
            idToken: localStorage.access_token,
          },
        }
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("POST /articles failed with error: ", err);
        reject(err);
      });
  });
};

export const newIdea = ({ email, idea }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("ideas", {
        email,
        topics: [idea],
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("POST /ideas failed with error: ", err);
        reject(err);
      });
  });
};

export const newQuery = ({ email, name, phone, message }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("queries", {
        email,
        name,
        phone,
        message,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("POST /queries failed with error: ", err);
        reject(err);
      });
  });
};

export const updateUserProfile = ({ profession, bio, socialmedia }) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        "userinfo",
        {
          profession,
          bio,
          socialmedia,
        },
        {
          params: {
            idToken: localStorage.access_token,
          },
        }
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("PUT /userInfo failed with err: ", err);
        reject(err);
      });
  });
};

export const approveArticles = ({ ids }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(
        "articles",
        {
          ids,
        },
        {
          params: {
            idToken: localStorage.access_token,
          },
        }
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("PATCH /articles failed with err: ", err);
        reject(err);
      });
  });
};

export const deleteArticle = ({ ids }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete("articles", {
        params: {
          idToken: localStorage.access_token,
        },
        data: { ids },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("DELETE /articles failed with err: ", err);
        reject(err);
      });
  });
};

export const makeAuthor = ({ email }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "author",
        {
          email,
        },
        {
          params: {
            idToken: localStorage.access_token,
          },
        }
      )
      .then((data) => resolve(data))
      .catch((err) => {
        console.error("POST /author failed with err: ", err);
        reject(err);
      });
  });
};

export const getYoutubeVideos = (
  { key, channelId } = {
    key: GOOGLE_API_KEY,
    channelId: YOUTUBE_CHANNEL_ID,
  }
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`https://www.googleapis.com/youtube/v3/search`, {
        params: {
          key,
          channelId,
          part: "snippet",
          maxResults: MAX_YOUTUBE_VIDEOS,
          order: "date",
          type: "video",
        },
      })
      .then(({ data }) => {
        resolve(data?.items ?? []);
      })
      .catch((err) => {
        console.error("GET /youtube failed with err: ", err);
        reject(err);
      });
  });
};

export const getUserProfile = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("userinfo", {
        params: {
          idToken: localStorage.access_token,
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("GET /userInfo failed with err: ", err);
        reject(err);
      });
  });
};

export const getAuthors = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("author", {
        params: {
          idToken: localStorage.access_token,
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("GET /author failed with error: ", err);
        reject(err);
      });
  });
};

export const deleteAuthor = ({ email }) => {
  return new Promise((resolve, reject) => {
    axios
      .delete("author", {
        params: {
          idToken: localStorage.access_token,
        },
        data: {
          email,
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("DELETE /author failed with error: ", err);
        reject(err);
      });
  });
};

export const getQueries = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("queries", {
        params: {
          idToken: localStorage.access_token,
        },
      })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("GET /queries failed with error: ", err);
        reject(err);
      });
  });
};
