import { useState } from "react";
import { useNavigate } from "react-router";
import { FormControl, OutlinedInput, Button } from "@mui/material";
import Editor from "./molecules/Editor";
import { AlertDialog } from "./molecules/Alert";

import { newArticle } from "../services/api";
import { withEvent } from "../utils";
import { ACCESS_ISSUE, ADMIN, AUTHOR } from "../contants";
import NotFound from "./molecules/NotFound";

const NewArticle = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [alertDialogInfo, setAlertDialogInfo] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleContentChange = (contentValue: string) => {
    setContent(contentValue);
  };

  const handleSubmit = () => {
    setIsSaving(true);
    newArticle({
      title,
      content,
      topics: tags.split(","),
    })
      .then((data) => {
        setIsSaving(false);
        setAlertType("success");
        setAlertDialogInfo("New article is published!");
        setTitle("");
        setContent("");
        setTags("");
        setIsOpen(true);
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
        setAlertType("error");
        setAlertDialogInfo("We are having some issues, Please try again!");
        setIsOpen(true);
      });
  };

  return localStorage.accesstype !== ADMIN &&
    localStorage.accesstype !== AUTHOR ? (
    <NotFound msg={ACCESS_ISSUE} />
  ) : (
    <div>
      <div className="article-new">
        <div className="tw-p-14 tw-pt-24 !tw-pb-16 md:tw-p-48">
          <h2 className="tw-text-4xl tw-mb-6">Publish new article..</h2>
          <FormControl
            sx={{ margin: "8px 0", width: "100%" }}
            variant="outlined"
          >
            <OutlinedInput
              placeholder="Enter title"
              value={title}
              onChange={withEvent(setTitle)}
            />
          </FormControl>
          <FormControl
            sx={{ margin: "8px 0", width: "100%" }}
            variant="outlined"
          >
            <OutlinedInput
              placeholder="Enter tags(comma seperated)"
              value={tags}
              onChange={withEvent(setTags)}
            />
          </FormControl>
          <Editor value={content} onChange={handleContentChange} />
          <div className="tw-mt-12 tw-flex tw-items-center tw-justify-center">
            <Button
              className="publish-new-btn"
              variant="contained"
              disabled={isSaving}
              onClick={handleSubmit}
            >
              Publish
            </Button>
          </div>
        </div>
      </div>
      <AlertDialog
        type={alertType}
        info={alertDialogInfo}
        open={isOpen}
        setOpen={setIsOpen}
      />
    </div>
  );
};

export default NewArticle;
