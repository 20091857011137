import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import notFoundSvg from "../../assets/icons/notfound.svg";
import { NotFoundSx } from "../styles";
import { NOT_FOUND_ISSUE } from "../../contants";

interface Props {
  msg?: string;
}

const NotFound = (props: Props) => {
  const { msg = NOT_FOUND_ISSUE } = props;
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        height: "90vh",
        ...NotFoundSx,
      }}
    >
      <img
        src={notFoundSvg}
        alt="No Data"
        style={{ height: "200px", marginBottom: "12px" }}
      />
      <Typography>{msg}</Typography>
      <Button
        className="tw-text-blue-600 tw-underline tw-cursor-pointer"
        onClick={() => navigate("/")}
      >
        Navigate to home
      </Button>
    </Stack>
  );
};

export default NotFound;
