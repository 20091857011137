import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { AlertDialog } from "./molecules/Alert";
import { newQuery } from "../services/api";
import auth from "../services/auth";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "720px",
    margin: "0 auto",
    "& .MuiTextField-root": {
      marginBottom: "16px",
    },
  },
  button: {
    alignSelf: "center",
    marginTop: "16px !important",
    backgroundColor: "#000 !important",
    paddingLeft: "20px !important",
    paddingRight: "20px !important",
  },
}));

const ContactForm = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [alertDialogInfo, setAlertDialogInfo] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = (e) => {
    setIsSaving(true);
    const element = document.getElementById(
      "contactus-form"
    ) as HTMLFormElement;
    const isValidForm = element.checkValidity();
    if (isValidForm) {
      e.preventDefault();
      newQuery({
        name,
        email,
        phone,
        message,
      })
        .then((data) => {
          setIsSaving(false);
          setAlertType("success");
          setAlertDialogInfo("We'll contact you soon!");
          setIsOpen(true);
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          navigate("/");
        })
        .catch((err) => {
          console.error(err);
          setAlertType("error");
          setAlertDialogInfo("We are having some issues, Please try again!");
          setIsOpen(true);
          setIsSaving(false);
        });
    } else {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    setName(user && user.displayName ? user && user.displayName : "");
    setEmail(user && user.email ? user && user.email : "");
    setPhone(user && user.phoneNumber ? user && user.phoneNumber : "");
  }, [user]);

  return (
    <div className="contactus tw-p-16 tw-pt-36 tw-pb-20">
      <h2 className="tw-text-4xl tw-text-center tw-mb-8">Contact Us</h2>
      <form
        id="contactus-form"
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          required
          type="email"
          label="Email"
          value={email}
          disabled={auth && auth.currentUser && email ? true : false}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          type="tel"
          label="Phone Number"
          value={phone}
          inputProps={{ minLength: 10, maxLength: 10 }}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          required
          multiline
          rows={4}
          label="Message"
          value={message}
          className="contact-us-textarea"
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSaving}
          className={classes.button}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </form>
      <AlertDialog
        type={alertType}
        info={alertDialogInfo}
        open={isOpen}
        setOpen={setIsOpen}
      />
    </div>
  );
};

export default ContactForm;
