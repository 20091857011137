// @ts-nocheck
import { Card, CardContent, CardMedia, Chip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import LinkWithArrow from "./LinkWithArrow";
import financeSvg from "../../assets/icons/finance.svg";
import { createMarkup, truncateString, useIsMobile } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginBottom: "24px",
    boxShadow: "0 0 6px #0000001f !important",
  },
  mediaH: {
    minWidth: 150,
    width: "20% !important",
    backgroundSize: "contain !important",
  },
  mediaV: {
    width: "100%",
    height: 0,
    paddingTop: "56.25%",
    backgroundSize: "contain !important",
  },
  content: {
    flex: 1,
    padding: "12px",
  },
  chip: {
    marginRight: "12px",
    marginBottom: "12px",
  },
}));

const MyCard = ({
  imageSrc = financeSvg,
  topics,
  title,
  content,
  to,
  userinfo,
  views,
  classesName = "",
  vertical = true,
}) => {
  const classes = useStyles();
  const isMob = useIsMobile();

  return (
    <Card
      className={`${classes.card} ${classesName}`}
      sx={isMob ? {} : vertical ? { maxWidth: "24%" } : {}}
    >
      <CardMedia
        className={vertical ? classes.mediaV : classes.mediaH}
        image={imageSrc}
        title={title}
      />

      <CardContent className={classes.content}>
        {isMob
          ? topics &&
            topics.length > 0 &&
            topics
              .slice(0, 2)
              .map((tag, index) => (
                <Chip key={index} label={tag} className={classes.chip} />
              ))
          : topics &&
            topics.length > 0 &&
            topics.map((tag, index) => (
              <Chip key={index} label={tag} className={classes.chip} />
            ))}

        <Typography variant="h6" component="h2">
          {vertical
            ? isMob
              ? truncateString(title, 30)
              : truncateString(title, 75)
            : title}
        </Typography>

        <Typography
          variant="span"
          color="textSecondary"
          className="tw-mb-4 tw-text-sm tw-flex tw-items-end"
        >
          <div
            className="article-content"
            dangerouslySetInnerHTML={createMarkup(content)}
          ></div>
          {vertical ? <p className="tw-pb-1">...</p> : null}
        </Typography>

        <span className={vertical && !isMob ? "tw-absolute tw-bottom-3" : ""}>
          <span
            className={`tw-flex tw-items-center ${
              vertical ? "tw-justify-between tw-mt-2" : "tw-mt-2"
            }`}
          >
            <span className="tw-pr-1">
              <DriveFileRenameOutlineIcon className="tw-mr-1" />
              {userinfo?.name ?? "User"}
            </span>
            <span className="tw-text-stone-500 tw-text-sm">
              {" "}
              | <RemoveRedEyeIcon fontSize="small" className="tw-mr-1" />
              {views}
            </span>
          </span>

          <LinkWithArrow
            to={to}
            text="Read Full Article"
            classnames="card-link"
          />
        </span>
      </CardContent>
    </Card>
  );
};

export default MyCard;
