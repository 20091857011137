import React, { useState } from "react";
import { Theme } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import { Backdrop, Box, CircularProgress } from "@mui/material";

// Internal components
import TopNav from "./components/molecules/TopNav";
import Home from "./components/Home";
import Articles from "./components/Articles";
import Article from "./components/Article";
import Calculator from "./components/Calculator";
import ContactForm from "./components/ContactForm";
import UserProfile from "./components/UserProfile";
import Footer from "./components/molecules/Footer";
import NewArticle from "./components/NewArticle";
import AdminPanel from "./components/AdminPanel";
import NotFound from "./components/molecules/NotFound";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <TopNav />
      <Backdrop
        className="tw-flex tw-flex-col"
        sx={{
          top: "64px !important",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
        onClick={() => setIsLoading(false)}
      >
        <CircularProgress color="inherit" />
        <span className="tw-mt-2 tw-text-l">Loading...</span>
      </Backdrop>
      <Box sx={{ minHeight: "95vh" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/new" element={<NewArticle />} />
          <Route
            path="/article/:id"
            element={<Article setIsLoading={setIsLoading} />}
          />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
      <Footer />
    </>
  );
};

export default App;
