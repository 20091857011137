import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Button, Chip, IconButton, Stack } from "@mui/material";
import { Delete, DriveFileRenameOutline } from "@mui/icons-material";
import "react-quill/dist/quill.snow.css";

import { AlertDialog } from "./molecules/Alert";

import { approveArticles, deleteArticle, getArticle } from "../services/api";
import { createMarkup } from "../utils";
import { ADMIN, APPROVED, PENDING } from "../contants";

const Article = ({ setIsLoading }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [tags, setTags] = useState("");
  const [articleStatus, setArticleStatus] = useState(APPROVED);
  const [isSaving, setIsSaving] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [alertDialogInfo, setAlertDialogInfo] = useState("");
  const [alertType, setAlertType] = useState("success");

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getArticle({
        id,
      })
        .then(({ data }) => {
          data = data.articles?.length > 0 ? data.articles[0] : {};
          setTitle(data?.title ?? "");
          setContent(data?.content ?? "");
          setAuthor(data?.userinfo?.name ?? "User");
          setTags(data.topics?.join(",") ?? "");
          setArticleStatus(data?.status ?? APPROVED);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setAlertType("error");
          setAlertDialogInfo(
            "We are having some issues, Please reload the page!"
          );
          setIsOpen(true);
          setIsLoading(false);
        });
    }
  }, [id]);

  const handleApprove = () => {
    const isConfirmed = window.confirm(
      "Are you sure, you want to approve this article?"
    );
    if (isConfirmed) {
      setIsSaving(true);
      approveArticles({
        ids: [id],
      })
        .then((data) => {
          setIsSaving(false);
          setAlertType("success");
          setAlertDialogInfo("Approved successfully!");
          setIsOpen(true);
          setTimeout(() => {
            navigate("/articles");
          }, 1000);
        })
        .catch((err) => {
          setIsSaving(false);
          setAlertType("error");
          setAlertDialogInfo("We are having some issues, Please try again!");
          setIsOpen(true);
        });
    }
  };

  const handleDelete = () => {
    const isConfirmed = window.confirm(
      "Are you sure, you want to delete this article?"
    );
    if (isConfirmed) {
      setIsSaving(true);
      deleteArticle({
        ids: [id],
      })
        .then((data) => {
          setIsSaving(false);
          setAlertType("success");
          setAlertDialogInfo("Deleted successfully!");
          setIsOpen(true);
          setTimeout(() => {
            navigate("/articles");
          }, 1000);
        })
        .catch((err) => {
          setIsSaving(false);
          setAlertType("error");
          setAlertDialogInfo("We are having some issues, Please try again!");
          setIsOpen(true);
        });
    }
  };

  return (
    <div className="article">
      <div className="tw-p-14 tw-pt-24 md:tw-p-48">
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 className="tw-text-4xl tw-mb-4">{title}</h2>
          {localStorage.accesstype === ADMIN && (
            <Stack
              sx={{
                flexDirection: "row",
                gap: "8px",
              }}
            >
              {articleStatus === PENDING && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#000",
                  }}
                  disabled={isSaving}
                  onClick={handleApprove}
                >
                  Approve
                </Button>
              )}
              <IconButton
                aria-label="delete"
                disabled={isSaving}
                onClick={handleDelete}
              >
                <Delete />
              </IconButton>
            </Stack>
          )}
        </Stack>
        <p className="tw-mt-3 tw-font-bold">
          <DriveFileRenameOutline className="tw-mr-1" />
          By {author}
        </p>
        <div
          className="article-content tw-pt-8 tw-pb-8 md:tw-p-8 md:tw-text-justify tw-leading-relaxed"
          dangerouslySetInnerHTML={createMarkup(content)}
        ></div>
        {tags ? (
          tags
            .split(",")
            .map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                className="tw-rounded-none tw-mr-2 chip"
              />
            ))
        ) : (
          <></>
        )}
      </div>
      <AlertDialog
        type={alertType}
        info={alertDialogInfo}
        open={isOpen}
        setOpen={setIsOpen}
      />
    </div>
  );
};

export default Article;
