import React, { useEffect, useState } from "react";
import Person2Icon from "@mui/icons-material/Person2";
import {
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getUserProfile, updateUserProfile } from "../services/api";
import NotFound from "./molecules/NotFound";
import { LOGIN_REQUIRED_ISSUE } from "../contants";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: "16px !important",
    backgroundColor: `#000 !important`,
  },
}));

const UserProfile = () => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profession, setProfession] = useState("");
  const [bio, setBio] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    getUserProfile().then(({ data }) => {
      setName(data?.name ?? "");
      setEmail(data?.email ?? "");
      setProfession(data?.profession ?? "");
      setBio(data?.bio ?? "");
      setLinkedInUrl(data?.socialmedai?.linkedin ?? "");
    });
  }, []);

  const handleFieldChange = (field: string, value: string) => {
    if (field === "profession") setProfession(value);
    else if (field === "bio") setBio(value);
    else setLinkedInUrl(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsSaving(true);
    event.preventDefault();
    updateUserProfile({
      profession,
      bio,
      socialmedia: {
        linkedin: linkedInUrl,
      },
    })
      .then(() => {
        setIsSaving(false);
      })
      .catch((err) => {
        setIsSaving(false);
      });
  };

  return !localStorage.access_token ? (
    <NotFound msg={LOGIN_REQUIRED_ISSUE} />
  ) : (
    <Container component="main" maxWidth="xs" className="tw-pt-12">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#000" }}>
          <Person2Icon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Personal Information
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="given-name"
                name="name"
                value={name}
                required
                fullWidth
                id="name"
                label="Name"
                autoFocus
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                value={email}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={profession}
                name="profession"
                label="Profession"
                id="profession"
                autoComplete="profession"
                onChange={(event) =>
                  handleFieldChange("profession", event?.target?.value ?? "")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={bio}
                name="bio"
                label="Bio"
                id="bio"
                autoComplete="bio"
                onChange={(event) =>
                  handleFieldChange("bio", event?.target?.value ?? "")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={linkedInUrl}
                name="linkedIn"
                label="Linked in url"
                id="linkedIn"
                autoComplete="linkedIn"
                onChange={(event) =>
                  handleFieldChange("linkedInUrl", event?.target?.value ?? "")
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            className={classes.button}
            disabled={isSaving}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UserProfile;
