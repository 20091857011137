import React from "react";
import { Stack } from "@mui/material";

interface Props {
  embedId: string;
  sx?: React.CSSProperties;
  iFrameSx?: React.CSSProperties;
}

const YoutubeVideo = (props: Props) => {
  const {
    embedId,
    sx = {},
    iFrameSx = { width: "100%", height: "350px" },
  } = props;
  return (
    <Stack
      sx={{
        boxShadow:
          "0px -25px 20px -20px rgba(0, 0, 0, 0.45), 25px 0 20px -20px rgba(0, 0, 0, 0.45), 0px 25px 20px -20px rgba(0, 0, 0, 0.45), -25px 0 20px -20px rgba(0, 0, 0, 0.45)",
        ...sx,
      }}
    >
      <iframe
        src={`https://www.youtube.com/embed/${embedId}?rel=0&amp;showinfo=0&enablejsapi=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Youtube"
        style={iFrameSx}
      />
    </Stack>
  );
};

export default YoutubeVideo;
