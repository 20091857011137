const CardSkeletonStyleV: React.CSSProperties = {
  display: "flex",
  height: "28rem",
  boxShadow: "0 0 6px #0000001f",
  marginBottom: "24px",
  borderRadius: "4px",
  flexDirection: "column" as "column",
};

const CardSkeletonStyleH = {
  display: "flex",
  height: "16rem",
  boxShadow: "0 0 6px #0000001f",
  marginBottom: "24px",
  borderRadius: "4px",
};

const MediaSkeletonSx = {
  width: "20%",
  height: "100%",
  marginRight: "12px",
};

const ContentSkeletonVStyle = {
  padding: "8px",
  width: "100%",
};

const ContentSkeletonHStyle = {
  padding: "8px",
  width: "80%",
};

const TagsSkeletonContainerSx = {
  display: "flex",
  gap: "8px",
};

const TagsSkeletonSx = {
  width: "50%",
  fontSize: "2rem",
};

const NoDataSx = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 !important",
  textAlign: "center",
  margin: "0 auto",
};

const YoutubeSectionSx = {
  padding: "4rem",
  borderTop: "1px solid #0000001f",
};

const YoutubeVideoSx = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  paddingTop: "24px",
};

const NotFoundSx = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 !important",
  textAlign: "center",
  margin: "0 auto",
};

export {
  CardSkeletonStyleH,
  CardSkeletonStyleV,
  MediaSkeletonSx,
  ContentSkeletonVStyle,
  ContentSkeletonHStyle,
  TagsSkeletonContainerSx,
  TagsSkeletonSx,
  NoDataSx,
  YoutubeSectionSx,
  YoutubeVideoSx,
  NotFoundSx,
};
