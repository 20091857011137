import { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  debounce,
  MobileStepper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useAuthState } from "react-firebase-hooks/auth";
import SearchIcon from "@mui/icons-material/Search";

import Card from "./molecules/Card";
import LinkWithArrow from "./molecules/LinkWithArrow";
import { AlertDialog } from "./molecules/Alert";
import ArticleSkeleton from "./molecules/ArticleSkeleton";
import NoData from "./molecules/NoData";

import { getArticles, getYoutubeVideos, newIdea } from "../services/api";
import auth from "../services/auth";
import { useIsMobile } from "../utils";
import YoutubeSection from "./molecules/YoutubeSection";
import { APPROVED } from "../contants";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
}));

const Home = () => {
  const classes = useStyles();

  const [user] = useAuthState(auth);

  const isMob = useIsMobile();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("");
  const [searchText, setSearchText] = useState("");
  const [articles, setArticles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alertDialogInfo, setAlertDialogInfo] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [articleCount, setArticleCount] = useState(1);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isTopicError, setIsTopicError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [youtubeVideoIds, setYoutubeVideoIds] = useState([]);

  useEffect(() => {
    localStorage.setItem("currentPage", JSON.stringify(1));
    getYoutubeVideos().then((data) => {
      const ids = data?.map((d) => d?.id?.videoId);
      setYoutubeVideoIds(ids);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getArticles({
      limit: isMob ? 3 : 4,
      title: searchText,
      status: APPROVED,
      // topics: searchText.replace(/\s/g, "")
    })
      .then(({ data }) => {
        setArticles(data?.articles ?? []);
        setArticleCount(data?.articles?.length ?? 1);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        setAlertType("error");
        setAlertDialogInfo(
          "We are having some issues, Please reload the page!"
        );
        setIsOpen(true);
      });
  }, [searchText]);

  useEffect(() => {
    setName(user && user.displayName ? user && user.displayName : "");
    setEmail(user && user.email ? user && user.email : "");
  }, [user]);

  const withEvent = (func: Function): React.ChangeEventHandler<any> => {
    return (event: React.ChangeEvent<any>) => {
      const { target } = event;
      func(target.value);
    };
  };

  const handleSearch = debounce((value) => {
    setSearchText(value);
  }, 500);

  const handleRequest = () => {
    if (!email || !topic) {
      setIsEmailError(!Boolean(email));
      setIsTopicError(!Boolean(topic));
      setAlertType("error");
      setAlertDialogInfo("Please fill the fields!");
      setIsOpen(true);
      return;
    } else if (email) {
      const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!EMAIL_REGEX.test(email)) {
        setIsEmailError(true);
        setAlertType("error");
        setAlertDialogInfo("Please enter valid email!");
        setIsOpen(true);
        return;
      }
    }
    setIsSaving(true);
    newIdea({
      email,
      idea: topic,
    })
      .then((data) => {
        setAlertType("success");
        setAlertDialogInfo("Your request is submitted!");
        setIsOpen(true);
        setEmail("");
        setTopic("");
        setIsEmailError(false);
        setIsTopicError(false);
        setIsSaving(false);
      })
      .catch((err) => {
        console.error(err);
        setAlertType("error");
        setAlertDialogInfo("We are having some issues, Please try again!");
        setIsOpen(true);
        setIsEmailError(false);
        setIsTopicError(false);
        setIsSaving(false);
      });
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div>
      <div className="blogs-header tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-16 tw-pt-32">
        <h2 className="tw-m-2 tw-text-white tw-text-3xl md:tw-text-5xl">
          Blog by NoTax
        </h2>
        <p className="tw-p-4 tw-text-slate-200 tw-text-lg tw-text-center">
          All-in-One hub for your Finance & Tax needs.
        </p>
        <TextField
          className={`${classes.input} tw-rounded-md`}
          placeholder="Search for article"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="start" disabled>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(event) => handleSearch(event.target.value)}
        />
      </div>
      <div className="tw-p-12 md:tw-p-16 tw-pb-4 md:tw-pb-16">
        <div className="articles-heading tw-flex tw-justify-between">
          <h2 className="tw-text-2xl tw-mb-4">
            {name
              ? `Hi ${name}, We've new article for you.`
              : "Hi, We've new Article for you."}
          </h2>
          {!isMob && (
            <LinkWithArrow
              to="/articles"
              text="View all articles"
              classnames="!tw-mb-4"
            />
          )}
        </div>
        <div
          className={`tw-flex tw-space-x-6 tw-flex-col md:tw-flex-row ${
            isMob || (!isLoading && articles?.length === 0)
              ? ""
              : "home-articles"
          }`}
        >
          {isMob ? (
            <>
              {isLoading ? (
                <ArticleSkeleton />
              ) : (articles?.length ?? 0) === 0 ? (
                <NoData term={searchText} />
              ) : (
                <>
                  <AutoPlaySwipeableViews
                    axis={"x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {articles.map((article, index) => {
                      return (
                        <div key={article.id}>
                          {Math.abs(activeStep - index) <= 2 ? (
                            <Card
                              key={article.id}
                              {...article}
                              to={`article/${article.id}`}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                  </AutoPlaySwipeableViews>
                  <MobileStepper
                    className="!tw-justify-center tw-mt-1 !tw-ml-0 stepper"
                    steps={articleCount}
                    position="static"
                    activeStep={activeStep}
                    backButton={null}
                    nextButton={null}
                  />
                </>
              )}
            </>
          ) : isLoading ? (
            Array.from([1, 2, 3, 4]).map((key) => {
              return (
                <ArticleSkeleton
                  key={`article_skeleton_${key}`}
                  style={{ flex: 1, height: "unset" }}
                />
              );
            })
          ) : (articles?.length ?? 0) === 0 ? (
            <NoData term={searchText} />
          ) : (
            articles.map((article) => {
              return (
                <Card
                  key={article.id}
                  {...article}
                  to={`article/${article.id}`}
                  classesName="tw-relative"
                />
              );
            })
          )}
        </div>
        {isMob && (
          <LinkWithArrow
            to="/articles"
            text="View all articles"
            classnames="!tw-mt-2 !tw-justify-center"
          />
        )}
      </div>
      {youtubeVideoIds?.length && <YoutubeSection ids={youtubeVideoIds} />}
      <div className="blogs-footer tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-16">
        <h2 className="tw-m-2 tw-text-white tw-text-5xl">
          Want us to cover any topic?
        </h2>
        <p className="tw-p-4 tw-text-slate-200 tw-text-lg">
          All about Finance & Tax related stuff, everything you can get here
          with just one click.
        </p>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-space-y-4 md:tw-space-y-0 md:tw-space-x-4 tw-mb-6">
          <TextField
            required
            type="email"
            error={isEmailError}
            className="tw-bg-white tw-rounded-t tw-w-72"
            id="filled-required"
            label="Email"
            variant="filled"
            placeholder="Enter your email"
            value={email}
            disabled={auth && auth.currentUser && email ? true : false}
            onChange={withEvent(setEmail)}
          />
          <TextField
            required
            error={isTopicError}
            className="tw-bg-white tw-rounded-t tw-w-72"
            id="filled-required"
            label="Topic"
            variant="filled"
            placeholder="Enter the topic"
            value={topic}
            onChange={withEvent(setTopic)}
          />
        </div>
        <Button
          className="tw-bg-white"
          variant="contained"
          size="large"
          disabled={isSaving}
          onClick={handleRequest}
        >
          Request
        </Button>
      </div>
      <AlertDialog
        type={alertType}
        info={alertDialogInfo}
        open={isOpen}
        setOpen={setIsOpen}
      />
    </div>
  );
};

export default Home;
