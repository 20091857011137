import React, { useState, useEffect } from "react";

export const useIsMobile = (maxWidth?: number) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const width = maxWidth || 767;
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth < width;
};

export const createMarkup = (text) => {
  return { __html: text };
};

export const truncateString = (str, charLength = 90) => {
  if (typeof str !== "string") {
    console.error(
      "utils error: truncateString : first argument passed was not a string. recieved type :",
      typeof str
    );
    return "";
  }
  if (typeof charLength !== "number") {
    console.error(
      "utils error: truncateString : second argument passed was not a number. recieved type :",
      typeof charLength
    );
    return "";
  }
  if (str.length > charLength) {
    return `${str.substr(0, charLength - 3)}...`;
  }
  return str;
};

export const getPathName = () => window.location.pathname;

export const withEvent = (func: Function): React.ChangeEventHandler<any> => {
  return (event: React.ChangeEvent<any>) => {
    const { target } = event;
    func(target.value);
  };
};

export const fireYouTubeVideosEvent = (iframes, func: "stopVideo" | "playVideo" | "pauseVideo") => { 
  Array.prototype.forEach.call(iframes, iframe => { 
    iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', 
  func }), '*');
 });
}