import { IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import { SocialLinks } from "../../contants";

const returnIcon = (id: string) => {
  if (id === "facebook") return <FacebookIcon fontSize="small" />;
  else if (id === "instagram") return <InstagramIcon fontSize="small" />;
  else if (id === "twitter") return <TwitterIcon fontSize="small" />;
  else if (id === "telegram") return <TelegramIcon fontSize="small" />;
  else if (id === "youtube") return <YouTubeIcon fontSize="small" />;
  else if (id === "linkedin") return <LinkedInIcon fontSize="small" />;
};

const Footer = () => {
  return (
    <p className="tw-bg-black tw-text-white tw-w-full tw-mt-16px tw-p-2 tw-text-center tw-font-extralight tw-flex tw-justify-center tw-items-center tw-flex-col md:tw-flex-row tw-relative tw-bottom-0">
      <span>Copyright © 2024 </span>
      <span className="social-btns tw-mx-1">
        |
        {SocialLinks.map((item) => {
          return (
            <IconButton
              key={item.id}
              onClick={() => {
                (window as Window).open(item.to, "_blank");
              }}
            >
              {returnIcon(item.id)}
            </IconButton>
          );
        })}
        |{" "}
      </span>
      <span>
        <a
          href="mailto:support@notax.in"
          className="tw-text-white tw-no-underline"
        >
          support@notax.in
        </a>
      </span>
    </p>
  );
};

export default Footer;
