// Port to a firebase service that does all this
import { getAuth, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FIREBASE_AUTH_CREDENTIAL } from "../config/config";

const firebaseApp = initializeApp(FIREBASE_AUTH_CREDENTIAL);
const analytics = getAnalytics(firebaseApp);
const auth = getAuth();

export const logout = (auth) => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      console.error("Error while logging out", error);
    });
};

export default auth;
