import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Pagination,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Card from "./molecules/Card";
import { AlertDialog } from "./molecules/Alert";

import { getArticles } from "../services/api";
import { useIsMobile } from "../utils";
import ArticleSkeleton from "./molecules/ArticleSkeleton";
import { ADMIN, ALL, ArticlesStatus, AUTHOR, PrimaryColor } from "../contants";
import NoData from "./molecules/NoData";

const Articles = () => {
  const navigate = useNavigate();
  const isMob = useIsMobile();

  const pageLength = 5;

  const [articles, setArticles] = useState([]);
  const [articleCount, setArticleCount] = useState(1);
  const [pageNo, setPageNo] = useState(
    JSON.parse(localStorage.getItem("currentPage")) ?? 1
  );
  const [isOpen, setIsOpen] = useState(false);
  const [alertDialogInfo, setAlertDialogInfo] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [statusValue, setStatusValue] = useState("all");

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNo(value);
    localStorage.setItem("currentPage", JSON.stringify(value));
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    if (value !== statusValue) {
      setStatusValue(value);
      handlePageChange(null, 1);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getArticles({
      limit: pageLength,
      offset: (pageNo - 1) * pageLength,
      status: statusValue === ALL ? "" : statusValue,
    })
      .then(({ data }) => {
        setArticles(data.articles ? data.articles : []);
        setArticleCount(data.totalcount ? data.totalcount : 0);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setAlertType("error");
        setAlertDialogInfo(
          "We are having some issues, Please reload the page!"
        );
        setIsOpen(true);
        setIsLoading(false);
      });
  }, [pageNo, statusValue]);

  return (
    <div className="articles tw-p-8 md:tw-p-16 !tw-pt-32 !tw-pb-8">
      <div className="articles-heading tw-flex tw-justify-between tw-border-b-2 tw-mb-4 tw-pb-4 md:tw-pb-0">
        <h2 className="tw-text-4xl md:tw-mb-4">Articles:</h2>
        {(localStorage.accesstype === ADMIN ||
          localStorage.accesstype === AUTHOR) && (
          <Stack
            sx={{
              flexDirection: "row",
            }}
          >
            <FormControl
              size="small"
              sx={{
                width: "140px",
                marginRight: "12px",
              }}
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={statusValue}
                label="Status"
                onChange={handleStatusChange}
              >
                {ArticlesStatus.map((statusField) => {
                  return (
                    <MenuItem key={statusField.id} value={statusField.value}>
                      {statusField.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!isMob && (
              <Button
                className="publish-new-btn"
                variant="contained"
                sx={{
                  height: "40px",
                  backgroundColor: PrimaryColor,
                }}
                onClick={() => navigate("/articles/new")}
              >
                Publish new
              </Button>
            )}
            {isMob && (
              <IconButton onClick={() => navigate("/articles/new")}>
                <AddIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </div>
      <div className="tw-p-4">
        {isLoading ? (
          Array.from([1, 2, 3, 4]).map((key) => {
            return (
              <ArticleSkeleton
                key={`article_skeleton_${key}`}
                isVertical={isMob}
              />
            );
          })
        ) : articles?.length === 0 ? (
          <NoData />
        ) : (
          articles.map((article) => {
            return (
              <Card
                key={article.id}
                {...article}
                to={`/article/${article.id}`}
                vertical={isMob ? true : false}
                classesName={
                  isMob ? "!tw-max-w-xs" : "!tw-flex-row !tw-h-64 !tw-mb-6"
                }
              />
            );
          })
        )}
      </div>
      <div className="tw-flex tw-justify-center">
        <Pagination
          count={Math.ceil(articleCount / pageLength)}
          page={pageNo}
          onChange={handlePageChange}
        />
      </div>
      <AlertDialog
        type={alertType}
        info={alertDialogInfo}
        open={isOpen}
        setOpen={setIsOpen}
      />
    </div>
  );
};

export default Articles;
